import { AxiosError } from "axios";

import client from "~/src/api-client";
import { LoginUserPayload, LoginUser } from "~/src/types/users";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api-client/users");

const scopes = [
  "create:consulta_appointment",
  "create:hso_appointment",
  "list:consulta_appointment",
  "list:hso_appointment",
  "list:hso_service",
  "create:hso_service",
  "delete:hso_service",
  "update:hso_service",
  "read:consulta_appointment",
  "read:consulta_service",
  "read:hso_appointment",
  "update:consulta_appointment",
  "update:hso_appointment",
  "list:hso_workers",
  "create:hso_workers",
  "update:hso_workers",
  "delete:hso_workers",
  "list:payments",
];

export async function loginUser({ email, password }: LoginUser): Promise<LoginUserPayload | undefined> {
  try {
    const res = await client.post<LoginUserPayload>("users/login_v2/", {
      email: email.trim().toLowerCase(),
      password,
      scopes: scopes.join(" "),
    });
    debug(`[loginUser] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[loginUser]", err);
    return undefined;
  }
}
