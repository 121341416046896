import React from "react";

function ArrowRightCircleIcon(props: { stroke?: string; className?: string }) {
  const { stroke, ...rest } = props;
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M9.16065 15.5833C13.0891 15.5833 16.2737 12.412 16.2737 8.49996C16.2737 4.58794 13.0891 1.41663 9.16065 1.41663C5.23221 1.41663 2.04759 4.58794 2.04759 8.49996C2.04759 12.412 5.23221 15.5833 9.16065 15.5833Z"
        stroke={stroke || "white"}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16066 11.3333L12.0059 8.49996L9.16066 5.66663"
        stroke={stroke || "white"}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.31543 8.5H12.0059"
        stroke={stroke || "white"}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowRightCircleIcon;
