import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";
import moment from "moment";

import { createMedicProfileRequest, getMedicProfile } from "../api-client/medics";
import LoadingSpinnerIcon from "../icons/LoadingSpinnerIcon";

import { loginUser } from "~/src/api-client/users";
import SEO from "~/src/components/SEO";
import { useContextUpdate, useContextValue } from "~/src/context";
import ArrowRightCircleIcon from "~/src/icons/ArrowRightCircleIcon";
import { LoginForm } from "~/src/types/forms";
import namespaced from "~/src/utils/debug";

const debug = namespaced("pages/login");

const LoginPage = () => {
  const { setSessionKeys, setUserKeys, setMedicKeys } = useContextUpdate();
  const { rehydrated, isLoggedIn } = useContextValue();

  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmitLogin = async (values: LoginForm) => {
    try {
      setLoggingIn(true);
      debug("will submit form with values\n", values);
      const res = await loginUser(values);
      if (res) {
        setSessionKeys({
          accessToken: res.access_token,
          expiresIn: res.expires_in,
          scope: res.scope,
          tokenType: res.token_type,
          setAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
        setUserKeys({ ...res["https://api.saludando.cl/jwt/claims"] });
        const twoRes = await getMedicProfile(res.access_token);
        if (twoRes) {
          setMedicKeys(twoRes.data);
          navigate("/app/telehealth/");
        } else {
          const resMedicProfileRequest = await createMedicProfileRequest(res.access_token);
          if (resMedicProfileRequest) {
            debug("Medic profile request created");
            navigate("/app/");
          } else {
            debug("Medic profile request failed");
            // eslint-disable-next-line no-alert
            alert(
              "Hubo un problema solicitando tu perfil de médico. Por favor intenta más tarde o comunícate con nosotros.",
            );
          }
        }
        close();
      } else {
        /**
         * TODO: also handle server errors & maybe give proper feedback
         */
        debug("wrong credentials");
        setLoggingIn(false);
      }
    } catch (err) {
      debug("failure at logging in:", err);
      setLoggingIn(false);
    }
  };

  useEffect(() => {
    if (rehydrated && isLoggedIn) {
      navigate("/app/telehealth/");
    }
  }, [rehydrated, isLoggedIn]);

  return (
    <React.Fragment>
      <SEO title="Examedi | Iniciar Sesión" />
      <div className="w-full min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="border-[1px] border-gray-200 w-[400px] bg-white p-[40px]">
          <div className="text-medium text-md md:text-xl">Ingresa a tu cuenta Examedi</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitLogin({ email, password });
            }}
          >
            <div className="mt-8">
              <div className="font-semibold text-sm md:text-md">Correo Electrónico</div>
              <input
                className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                type="email"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="mt-4">
              <div className="font-semibold text-sm md:text-md">Contraseña</div>
              <input
                className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                type="password"
                name="search"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="mt-4 w-full flex items-center justify-center col-span-2">
              <button
                type="submit"
                className={clsx(
                  "font-medium",
                  "hover:bg-gray-700",
                  "transition",
                  "ease-in-out",
                  "py-4",
                  "px-8",
                  "mt-4",
                  "text-white",
                  "rounded-full",
                  "bg-gradient-to-r from-[#607CEE] to-[#FF7575]",
                  "hover:transition hover:duration-1000",
                  "hover:from-[#FF7575] hover:to-[#607CEE]",
                  "text-sm md:text-base",
                  "flex items-center",
                )}
              >
                {loggingIn ? (
                  <LoadingSpinnerIcon />
                ) : (
                  <React.Fragment>
                    Ingresar <ArrowRightCircleIcon className="ml-[10px]" />
                  </React.Fragment>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
